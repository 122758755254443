'use client';

import { useParams, usePathname } from '@mentimeter/next-navigation';
import { useReportWebVitals } from 'next/web-vitals';
import { sendDistribution } from '@mentimeter/errors';

const PERFORMANCE_METRICS = ['FCP', 'LCP', 'CLS', 'TTFB'];

export function WebVitals() {
  let currentPath = usePathname();
  const params = useParams();

  useReportWebVitals((metric) => {
    const envName = globalThis.__mentimeterEnv['NEXT_PUBLIC_ENVIRONMENT_NAME'];

    if (!currentPath) {
      return;
    }
    // For other voting routes, there are no dynamic parameters
    // Will need a better way of handling this when scaling to more apps
    if (params && params.voteParams) {
      currentPath = '/[...voteParams]';
    }

    const isStage = envName === 'stage';
    const isSample = envName !== 'prod' || Math.random() < 0.1;
    const isMetricName = PERFORMANCE_METRICS.includes(metric.name);
    const isMetricType = metric.navigationType === 'navigate';

    if (isStage && isSample && isMetricName && isMetricType) {
      const value = Math.trunc(metric.value);
      const metricName: `mmjs.${string}` = `mmjs.${metric.name.toLowerCase()}`;
      sendDistribution({
        name: metricName,
        value,
        tags: [
          `route:${currentPath}`,
          `env:${globalThis.__mentimeterEnv['NEXT_PUBLIC_ENVIRONMENT_NAME']}`,
        ],
      });
    }
  });

  return null;
}
